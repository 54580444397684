<template>
  <div
    class="columns is-multiline is-marginless has-padding-50 has-padding-bottom-400"
  >
    <div class="column is-12">
      <collapse title="Invoice">
        <invoice :invoice-id="invoiceId" />
      </collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminInvoice",
  components: {
    invoice: () => import("@shared/invoices/_invoice")
  },
  data() {
    return {
      invoiceId: this.$route.params.invoiceId
    };
  },
  beforeDestroy() {
    this.$store.dispatch("invoices/unobserveInvoice", {
      invoiceId: this.invoiceId
    });
  },
  created() {
    this.$store
      .dispatch("invoices/observeInvoice", { invoiceId: this.invoiceId })
      .catch(() => {
        this.invoiceNotFound();
      });
  },
  methods: {
    invoiceNotFound() {
      this.$router.push(`/admin/invoices`);
      this.$toast.open({
        message: `Invoice not found`,
        type: "is-danger",
        position: "is-bottom"
      });
    }
  }
};
</script>
